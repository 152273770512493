import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout.tsx";
import BackToTopButton from '../components/BackToTopButton';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "1-introduction"
    }}>{`1. Introduction`}</h2>
    <p>{`C19testing.co.uk is owned and operated by Selph Limited. Selph Limited (`}<strong parentName="p">{`Selph`}</strong>{`, `}<strong parentName="p">{`“we”`}</strong>{`, `}<strong parentName="p">{`“us”`}</strong>{`, `}<strong parentName="p">{`“our”`}</strong>{`) is committed to protecting the privacy of the users and visitors of our website. This Privacy Policy states our policy and practices regarding the collection and use of information via our website.`}</p>
    <p>{`We want to provide a safe and secure user experience. We will ensure that the information you submit to us, or which we collect, via various channels (including our website or through written correspondence such as e-mail), is only used for the purposes set out in this Privacy Policy.`}</p>
    <p>{`Through this Privacy Policy we aim to inform you about the types of personal data we collect, the purposes for which we use the data and the ways in which the data is handled. We also aim to satisfy the obligation of transparency under the UK General Data Protection Regulation (`}<strong parentName="p">{`“UK GDPR”`}</strong>{`) and national laws implementing UK GDPR, like the Data Protection Act 2018.`}</p>
    <p>{`For the purpose of this Privacy Policy the controller of personal data is Selph Limited and our contact details are set out in the “Contact Us” section at the end of this Privacy Policy.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "2-the-information-we-collect"
    }}>{`2. The Information We Collect`}</h2>
    <p>{`In general, you can visit the C19 Testing website without identifying yourself or revealing any personal information.`}</p>
    <p>{`However, you may choose to provide us with personally identifiable information. In some cases, this is required in order to purchase products or services from us, or to register for an account with us. Personally identifiable information is information that can be used to identify you, such as your name, email address, telephone number, or similar information. Where you are providing us with personally identifiable information about other people, such as family members (including dependants), this privacy policy also covers how we handle their information.`}</p>
    <p>{`Within the C19 Testing website, you can make requests for information, create and manage an online account and order products and services. The types of personal information collected on these pages includes some or all of the following:`}</p>
    <h3 {...{
      "id": "information-you-give-us-when-setting-up-your-online-account-completing-questionnaires-and-purchasing-products-andor-services"
    }}>{`Information you give us (when setting up your online account, completing questionnaires and purchasing products and/or services):`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Contact details:`}</strong>{` name, surname, email address, telephone number and address`}</li>
      <li parentName="ul"><strong parentName="li">{`Demographics:`}</strong>{` date of birth, sex and ethnicity`}</li>
      <li parentName="ul"><strong parentName="li">{`Symptoms:`}</strong>{` answers to routine clinical questions about physical and mental health symptoms`}</li>
      <li parentName="ul"><strong parentName="li">{`Medical history:`}</strong>{` medical history, drug history, vaccination history, family history.`}</li>
      <li parentName="ul"><strong parentName="li">{`Travel-related data:`}</strong>{` answers to questions about your past or future foreign travel plans. These questions may be legally required by the UK government for some products. In some cases, we will collect an image of the personal details page of your passport.`}</li>
      <li parentName="ul"><strong parentName="li">{`Payment information`}</strong></li>
    </ul>
    <p>{`We collect this information to allow us to provide you with a high-quality, personalised service. Certain information, such as contact details and demographics are essential for some products and services. Other information, such as symptoms and medical and lifestyle history, are optional but help us to provide you with more personalised health insights. For the most part, this information would be entered by you, but in some cases, could be collected from devices (e.g. apps) or wearables that you may choose to pair with us. We will inform you if information will be captured in this way.`}</p>
    <h3 {...{
      "id": "information-we-may-collect-when-you-use-our-website"
    }}>{`Information we may collect when you use our website:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Technical information:`}</strong>{` IP address, domain names, country you’re visiting from, your web browser type and version, time-zone setting, browser plug-in types and versions, operating system and platform.`}</li>
      <li parentName="ul"><strong parentName="li">{`Visit information:`}</strong>{` information about the pages you visit on our website, how long you spend on each page, how you interact with page content (e.g. clicks, scrolls and mouse-overs) and any products or services you request.`}</li>
    </ul>
    <p>{`This information is collected anonymously and helps us to optimise our website performance to meet your needs. Please see our `}<a parentName="p" {...{
        "href": "https://www.c19testing.co.uk/cookie-policy",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Cookie Policy`}</a>{` for more details.`}</p>
    <h3 {...{
      "id": "information-generated-by-us-or-our-partners"
    }}>{`Information generated by us or our partners:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Test results:`}</strong>{` generated by users conducting point-of-care tests or our laboratory partner when you submit samples for testing. Results will be held both by us and our partner laboratory (currently The Doctors Laboratory and Salient Bio). Only tests you have requested will be performed and your samples will not be used for anything else.`}</li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "3-how-we-use-your-personal-information"
    }}>{`3. How We Use Your Personal Information`}</h2>
    <p>{`You can be assured that information you provide voluntarily will only be used in connection with your business relationship with Selph. We will hold, use and disclose your personally identifiable information for our legitimate business purposes including:`}</p>
    <ul>
      <li parentName="ul">{`to process and fulfil testing kit orders, including capturing results and generating certificates;`}</li>
      <li parentName="ul">{`to provide other products and/or services to you;`}</li>
      <li parentName="ul">{`to maintain our business relationship, where you are a visitor of our website or a user;`}</li>
      <li parentName="ul">{`to deal with your enquiries and complaints; to administer our website;`}</li>
      <li parentName="ul">{`to keep our website and systems secure and prevent fraud;`}</li>
      <li parentName="ul">{`where relevant, to meet legal, regulatory or compliance needs;`}</li>
      <li parentName="ul">{`to better understand your needs; and,`}</li>
      <li parentName="ul">{`to provide a better service including through conducting market research.`}</li>
    </ul>
    <p>{`When you sign up to our newsletter, we may use your information to contact you, to provide you with marketing information we think would be of particular interest. At a minimum, we will always give you the choice to opt-out of receiving such direct marketing.`}</p>
    <p>{`We will not use or share the personally identifiable information provided to us online in ways unrelated to the uses described in this statement. More information about how we may share your information can be found at section 5.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "4-the-legal-basis-for-processing-your-personal-information"
    }}>{`4. The Legal Basis For Processing Your Personal Information`}</h2>
    <p>{`Under UK GDPR, the main grounds that we rely upon in order to process personal information of users and visitors of our websites are the following:`}</p>
    <ul>
      <li parentName="ul">{`(a) `}<strong parentName="li">{`Necessary for entering into, or performing, a contract`}</strong>{`: in order to perform obligations that we undertake in providing products or services to you, or in order to take steps at your request to enter into a contract with us, it will be necessary for us to process your personal data;`}</li>
      <li parentName="ul">{`(b) `}<strong parentName="li">{`Necessary for compliance with a legal obligation`}</strong>{`: we are subject to certain legal requirements which may require us to process your personal data. We may also be obliged by law to disclose your personal data to a regulatory body or law enforcement agency;`}</li>
      <li parentName="ul">{`(c) `}<strong parentName="li">{`Necessary for the purposes of legitimate interests`}</strong>{`: either we, or a third party, will need to process your personal data for the purposes of our (or a third party’s) legitimate interests, provided we have established that those interests are not overridden by your rights and freedoms, including your right to have your personal data protected. Our legitimate interests include responding to requests and enquiries from you or a third party, optimising our website and user experience, informing you about and providing you with our products and services and ensuring that our operations are conducted in an appropriate and efficient manner; or`}</li>
      <li parentName="ul">{`(d) `}<strong parentName="li">{`Consent`}</strong>{`: in some circumstances, we may ask for your consent to process your personal data in a particular way.`}</li>
    </ul>
    <p>{`When we collect special category data, e.g. vaccination status, ethnicity or test result data, we rely on the following to process this type of personal information about you:`}</p>
    <ul>
      <li parentName="ul">{`(a) `}<strong parentName="li">{`Health and social care`}</strong>{`: the processing of personal information is necessary for the purposes of health and social care and the processing is overseen by an appropriate health professional;`}</li>
      <li parentName="ul">{`(b) `}<strong parentName="li">{`Public Interest`}</strong>{`: the processing of personal information is necessary for reasons of public interest in the area of public health and the processing is overseen by an appropriate health professional;`}</li>
      <li parentName="ul">{`(c) `}<strong parentName="li">{`Consent`}</strong>{`: in some circumstances, we may ask for your consent to process your special category data in a particular way, e.g. where your employer requires you to carry out a test.`}</li>
    </ul>
    <h2 {...{
      "id": "5-how-we-share-your-personal-information"
    }}>{`5. How We Share Your Personal Information`}</h2>
    <p>{`We may share your personal information with other parties to allow us to provide our service to you. For example, we use third parties to host our website, manage our databases, process payments and perform our laboratory analyses.`}</p>
    <h3 {...{
      "id": "routine-data-sharing-for-administrative-purposes"
    }}>{`Routine data sharing for administrative purposes:`}</h3>
    <p>{`Details of third parties with which we routinely share information for the purpose of delivering our products and services are set out below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Web services:`}</strong>{` Netlify, Amazon Web Services, Mailchimp, Trustpilot`}</li>
      <li parentName="ul"><strong parentName="li">{`Website analytics and search engine providers:`}</strong>{` Google Analytics`}</li>
      <li parentName="ul"><strong parentName="li">{`Laboratory partners:`}</strong>{` The Doctors Laboratory, Salient Bio`}</li>
      <li parentName="ul"><strong parentName="li">{`Payment processing:`}</strong>{` Stripe`}</li>
      <li parentName="ul"><strong parentName="li">{`Courier and delivery:`}</strong>{` DPD, Royal Mail`}</li>
    </ul>
    <p>{`Wherever possible, personal information that we share with these third parties will be in an anonymised and aggregated format from which individuals cannot be identified.`}</p>
    <h3 {...{
      "id": "routine-data-sharing-with-specific-business-entities"
    }}>{`Routine data sharing with specific business entities:`}</h3>
    <p>{`We may provide our test kits to a business or other legal entity for them to give to their staff for the purpose of medical testing. In these cases, we may offer individual employees using our test kits the option to consent to sharing their personal information – including test results and other medical data generated by us – with their employer. This will only be on an `}<em parentName="p">{`opt-in`}</em>{` basis and consent to share may be withdrawn at any time. The specific data being shared, whom the data is being shared with and the purpose of data sharing will be stated at the time of seeking consent from an individual. Although we make every reasonable effort to ensure that the information governance practices of business with whom we share data with are as rigorous as our own, we cannot be held responsible for the breach or misuse of data once it is under the control of a third party.`}</p>
    <h3 {...{
      "id": "sharing-of-aggregate-data-for-research-or-marketing"
    }}>{`Sharing of aggregate data for research or marketing:`}</h3>
    <p>{`On occasion, we may display statistics based on aggregated, anonymised data on our websites for the purpose of research and marketing. Individuals will not be identifiable from this data.`}</p>
    <h3 {...{
      "id": "other-purposes-for-sharing"
    }}>{`Other purposes for sharing:`}</h3>
    <p>{`We may share your personal information with other parties in the following circumstances:`}</p>
    <ul>
      <li parentName="ul">{`We may share anonymised and aggregated data, which includes laboratory analyses and personal information, with third parties such as research companies or other healthcare companies. Individuals would not be identifiable from this information.`}</li>
      <li parentName="ul">{`We may disclose your personal information without your consent if required to do so by applicable law, regulation or as part of a legal process. This includes informing Public Health England / UK Health Security Agency of results and associated personal information for notifiable infectious diseases; a list of which can be found on the gov.uk website.`}</li>
      <li parentName="ul">{`If we receive a request from a regulatory body or law enforcement agency, and if permitted under UK GDPR and other laws, we may disclose certain personal information to such bodies or agencies`}</li>
      <li parentName="ul">{`If we or our business merges with or is acquired by another business or company in the future, we will share your personal information with the new owners of the business or company and their advisors. If this happens, we will notify users and visitors of our website of such an event.`}</li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "6-how-long-we-will-hold-your-information"
    }}>{`6. How Long We Will Hold Your Information`}</h2>
    <p>{`In line with UK Department of Health advice, we will keep health-related information (e.g. test results and medical records) for at least 8 years. We will retain other personal information for as long as you continue to use our services. You can always request that we stop processing or delete your personal information.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "7-your-rights-regarding-information-we-hold-about-you"
    }}>{`7. Your Rights Regarding Information We Hold About You`}</h2>
    <p>{`You have certain rights in relation to personal information we hold about you. Details of these rights and how to exercise them are set out below. We will require evidence of your identity before we are able to act on your request.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Right of Access`}</strong></p>
    <p>{`You have the right at any time to ask us for a copy of the personal information about you that we hold and to check that we are lawfully processing it.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Right of Correction or Completion`}</strong></p>
    <p>{`If personal information we hold about you is not accurate, out of date or incomplete, you have a right to have the data rectified, updated or completed. You can let us know by contacting us at `}<a parentName="p" {...{
        "href": "mailto:support@c19testing.co.uk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`support@c19testing.co.uk`}</a>{`.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Right of Erasure`}</strong></p>
    <p>{`In certain circumstances, you have the right to request that personal information we hold about you is erased e.g. if the information is no longer necessary for the purposes for which it was collected or processed or our processing of the information is based on your consent and there are no other legal grounds on which we may process the information.`}</p>
    <p>{`Where your personal information has been passed on to one or more of our third-party partners, we will endeavour to request they also erase your personal information. These third parties may have their own right of erasure procedures and may decline our request. In these cases, we will provide you with details about how to contact them directly.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Right to Object to or Restrict Processing`}</strong></p>
    <p>{`In certain circumstances, you have the right to object to our processing of your personal information by contacting us `}<a parentName="p" {...{
        "href": "mailto:support@c19testing.co.uk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`support@c19testing.co.uk`}</a>{`. For example, if we are processing your information on the basis of our legitimate interests and there are no compelling legitimate grounds for our processing which override your rights and interests. You also have the right to object to use of your personal information for direct marketing purposes.`}</p>
    <p>{`You may also have the right to restrict our use of your personal information, such as in circumstances where you have challenged the accuracy of the information and during the period where we are verifying its accuracy.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Right of Data Portability`}</strong></p>
    <p>{`In certain instances, you have a right to receive any personal information that we hold about you in a structured, commonly used and machine-readable format. You can ask us to transmit that information to you or directly to a third party organisation.`}</p>
    <p>{`The above right exists only in respect of personal information that:`}</p>
    <ul>
      <li parentName="ul">{`you have provided to us previously;`}</li>
      <li parentName="ul">{`and is processed by us using automated means.`}</li>
    </ul>
    <p>{`While we are happy for such requests to be made, we are not able to guarantee technical compatibility with a third party organisation’s systems. We are also unable to comply with requests that relate to personal information of others without their consent.`}</p>
    <p>{`You can exercise any of the above rights by contacting us at `}<a parentName="p" {...{
        "href": "mailto:support@c19testing.co.uk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`support@c19testing.co.uk`}</a>{`.`}</p>
    <p>{`Most of the above rights are subject to limitations and exceptions. We will provide reasons if we are unable to comply with any request for the exercise of your rights.`}</p>
    <p>{`To the extent that we are processing your personal information based on your consent, you have the right to withdraw your consent at any time.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "8-use-of-cookies"
    }}>{`8. Use Of Cookies`}</h2>
    <p>{`“Cookies” are small text files placed on your device (e.g. computer, phone or tablet) when viewing certain pages on our website. For information on how we use cookies please see our separate `}<a parentName="p" {...{
        "href": "https://www.c19testing.co.uk/cookie-policy",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`“Cookie Policy”`}</a>{`.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "9-complaints"
    }}>{`9. Complaints`}</h2>
    <p>{`If you are unhappy about our use of your personal information, you can contact us at `}<a parentName="p" {...{
        "href": "mailto:support@c19testing.co.uk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`support@c19testing.co.uk`}</a>{`. You are also entitled to lodge a complaint with the UK Information Commissioner’s Office using any of the below contact methods:`}</p>
    <p><strong parentName="p">{`Telephone:`}</strong>{` 0303 123 11113`}</p>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://ico.org.uk/concerns/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://ico.org.uk/concerns/`}</a></p>
    <p><strong parentName="p">{`Post:`}</strong>{` Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "10-security"
    }}>{`10. Security`}</h2>
    <p>{`We have put in place measures to ensure the security of the information we collect and store about you and will use our reasonable endeavours to protect your personal data from unauthorised disclosure and/or access including through the use of network and database security measures. Despite our best efforts, there is always some risk in storing and transferring information over electronic media and we cannot guarantee the absolute security of any data which is collected and stored. However, were such a data breach to occur, we do guarantee to act swiftly in an effort to minimise its effects.`}</p>
    <p>{`In recognition of the value of your personal financial information, we do not store any payment card details – most payments are processed by Stripe or PayPal and bespoke orders of multiple Products involving invoicing will be processed via a bank transfer. We do not store users’ payment card details.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "11-other-websites"
    }}>{`11. Other Websites`}</h2>
    <p>{`Please note that clicking on links and banner advertisements on our websites can result in your browser accessing a third-party website, where data privacy practices are different to that of Selph.`}</p>
    <p>{`We are not responsible for, and have no control over, information that is submitted or collected by these third parties and you should consult their privacy policies.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "12-international-transfers"
    }}>{`12. International Transfers`}</h2>
    <p>{`Some of our external third parties are based outside the UK so their processing of your personal data will involve a transfer of data outside the UK.`}</p>
    <p>{`Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Where we use certain service providers, we may use specific contracts approved for use in the UK which give personal data the same protection it has in the UK.`}</p>
      </li>
    </ul>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "13-changes-to-our-privacy-policy"
    }}>{`13. Changes To Our Privacy Policy`}</h2>
    <p>{`Our Privacy Policy is subject to change from time to time and, if we update it, we will post these changes on this page so that you will be aware of how we use your information.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <h2 {...{
      "id": "14-contact-us"
    }}>{`14. Contact Us`}</h2>
    <p>{`If you have any enquiries or if you would like to contact us about our processing of your personal information, including to exercise your rights as outlined above, please contact us centrally by any of the methods below. When you contact us, we will ask you to verify your identity.`}</p>
    <p><strong parentName="p">{`Contact name:`}</strong>{` C19 Testing`}</p>
    <p><strong parentName="p">{`Email:`}</strong>{` `}<a parentName="p" {...{
        "href": "mailto:support@c19testing.co.uk",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`support@c19testing.co.uk`}</a></p>
    <p>{`Our registered office is at: Selph Ltd, 124 City Road, London, EC1V 2NX.`}</p>
    <BackToTopButton mdxType="BackToTopButton" />
    <p><strong parentName="p">{`Last updated: 21/02/2022`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      